import React from 'react'
import styles from './styles/skills.module.css'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'

export const Skills = () => {
  const data = useStaticQuery(graphql`
    query {
      css: file(
        sourceInstanceName: { eq: "assets" }
        absolutePath: { regex: "/css/" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      graphql: file(
        sourceInstanceName: { eq: "assets" }
        absolutePath: { regex: "/graphql/" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      html: file(
        sourceInstanceName: { eq: "assets" }
        absolutePath: { regex: "/html/" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      javascript: file(
        sourceInstanceName: { eq: "assets" }
        absolutePath: { regex: "/javascript/" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nodejs: file(
        sourceInstanceName: { eq: "assets" }
        absolutePath: { regex: "/nodejs/" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      react: file(
        sourceInstanceName: { eq: "assets" }
        absolutePath: { regex: "/icons8-react/" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section className={styles.container}>
      <h1>Skills</h1>
      <div className={styles.iconsContainer}>
        <div className={styles.icons} data-aos="zoom-in">
          <Image fluid={data.css.childImageSharp.fluid} />
        </div>
        <div className={styles.icons} data-aos="zoom-in">
          <Image fluid={data.html.childImageSharp.fluid} />
        </div>
        <div className={styles.icons} data-aos="zoom-in">
          <Image fluid={data.javascript.childImageSharp.fluid} />
        </div>
        <div className={styles.icons} data-aos="zoom-in">
          <Image fluid={data.nodejs.childImageSharp.fluid} />
        </div>
        <div className={styles.icons} data-aos="zoom-in">
          <Image fluid={data.graphql.childImageSharp.fluid} />
        </div>
        <div className={styles.icons} data-aos="zoom-in">
          <Image fluid={data.react.childImageSharp.fluid} />
        </div>
      </div>
    </section>
  )
}
