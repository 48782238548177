import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import styles from './styles/aboutme.module.css'

export const AboutMe = ({ scroll }) => {
  const data = useStaticQuery(graphql`
    {
      file(
        sourceInstanceName: { eq: "assets" }
        absolutePath: { regex: "/melvin.jpg/" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section className={styles.container}>
      <h1 className={styles.title}>About Me</h1>
      <div className={styles.imageContainer} data-aos="fade-right">
        <Image fluid={data.file.childImageSharp.fluid} />
      </div>
      <div className={styles.text} data-aos="fade-up">
        <p>
          I am a web developer from the Philippines. I'm proficient in React,
          and Gatsby. I'm also a writer who likes to delve into interesting
          ideas and look at it in different perspectives. I write because I want
          to understand how the world works, and know my place in it.
        </p>
        <p>
          If you want to work with me on your personal project like a portfolio,
          or image gallery, hit me up!
        </p>
        <button onClick={scroll} className="first btn">
          Let's Talk
        </button>
      </div>
    </section>
  )
}
