import React, { useEffect, useState, useRef } from 'react'
import BIRDS from 'vanta/dist/vanta.birds.min'
import * as THREE from 'three'
import styles from './styles/hero.module.css'

export const Hero = () => {
  const [vantaEffect, setVantaEffect] = useState(0)
  const myRef = useRef(null)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        BIRDS({
          el: myRef.current,
          THREE: THREE,
        })
      )
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])
  return (
    <section ref={myRef} className={styles.container}>
      <div className={styles.name}>
        <h1>Chris Melvin Factoriza</h1>
        <h3>Developer</h3>
      </div>
    </section>
  )
}
