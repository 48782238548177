import React from 'react'
import Image from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import styles from './styles/projects.module.css'

export const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      dogs: file(
        sourceInstanceName: { eq: "assets" }
        absolutePath: { regex: "/dogs.png/" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      starwars: file(
        sourceInstanceName: { eq: "assets" }
        absolutePath: { regex: "/starwars.jpg/" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reaction: file(
        sourceInstanceName: { eq: "assets" }
        absolutePath: { regex: "/reaction.png/" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section className={styles.container}>
      <h1 className={styles.title}>Projects</h1>
      <div
        className={`${styles.description} ${styles.description1}`}
        data-aos="fade-right"
      >
        <h2>Dog Images Gallery</h2>
        <p>Project made with react using an open-source API.</p>
        <a href="https://caninegallery.onrender.com/">
          <button class="btn first">See Live</button>
        </a>
      </div>
      <div className={`${styles.image} ${styles.image1}`} data-aos="fade-left">
        <Image fluid={data.dogs.childImageSharp.fluid} />
      </div>
      <div
        className={`${styles.description} ${styles.description2}`}
        data-aos="fade-right"
      >
        <h2>Emoji Reaction</h2>
        <p>
          This project was made for fun. Made with simple HTML, CSS, and
          Javascript
        </p>
        <a href="http://reaction.surge.sh/">
          <button class="btn first">See Live</button>
        </a>
      </div>
      <div className={`${styles.image} ${styles.image2}`} data-aos="fade-left">
        <Image fluid={data.reaction.childImageSharp.fluid} />
      </div>

      <div
        className={`${styles.description} ${styles.description3}`}
        data-aos="fade-right"
      >
        <h2>Star wars</h2>
        <p>
          A school project I made with HTML, CSS, and Javascript. It features
          parallax scrolling in the index page.
        </p>
        <a href="https://chris-melvin.github.io/starwars">
          <button class="btn first">See Live</button>
        </a>
      </div>
      <div className={`${styles.image} ${styles.image3}`} data-aos="fade-left">
        <Image fluid={data.starwars.childImageSharp.fluid} />
      </div>
    </section>
  )
}
