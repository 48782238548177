import React from 'react'
import styles from './styles/contact.module.css'

export const Contact = ({ reference }) => {
  return (
    <section className={styles.container} data-aos="zoom-in" ref={reference}>
      <h1>Contact</h1>
      <h3>Do you want to work with me? Awesome</h3>
      <a href="https://www.linkedin.com/in/chris-melvin/">
        <button class="btn first">Let's Talk</button>
      </a>
    </section>
  )
}
