import React, { useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Hero } from '../components/Hero'
import { AboutMe } from '../components/AboutMe'
import { Skills } from '../components/Skills'
import { Projects } from '../components/Projects'
import { Contact } from '../components/Contact'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Messenger } from '../components/Messenger'

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  // const posts = data.allMarkdownRemark.edges
  useEffect(() => {
    Aos.init({ duration: 600, easing: 'ease-in-sine', delay: 100 })
  }, [])

  const scrollRef = useRef(null)
  const handleClick = () =>
    scrollRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Chris Melvin" />
      {/* <Bio /> */}
      <Hero />
      <AboutMe scroll={handleClick} />
      <Skills />
      <Projects />
      <Contact reference={scrollRef} />
      <Messenger />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    avatar: file(absolutePath: { regex: "/tool.png/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
